import { Form, Formik } from "formik";
import { useState, useRef } from "react";
import CustomInput from "../../component/form/customInput";
import CustomButton, { ResetButton } from "../../component/form/customButton";
import Loader from "../../component/layout/loader";
import { Api } from "../../api/api";
import { useNavigate } from "react-router-dom";
import SweetAlert from 'react-bootstrap-sweetalert';

const ContactUs = () => {
  const [loading, setLoading] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const [errorMsg, setErrorMessage] = useState('');
  const [showErrorDialog, setShowErrorDialog] = useState(false);
  const navigate = useNavigate();
  const formikRef = useRef(null);

  const handleResetForm = () => {
    if (formikRef.current) {
      formikRef.current.resetForm();
    }
  };

  const handleCloseError = () => {
    setShowErrorDialog(false);
  };

  const validate = (values) => {
    const errors = {};

    if (!values.name) {
      errors.name = "Name is required";
    }

    if (!values.email) {
      errors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(values.email)) {
      errors.email = "Invalid email address";
    }

    /*
    if (!values.subject) {
      errors.subject = "Subject is required";
    }
      */
    if (!values.phone) {
      errors.subject = "Phone number is required";
    }

    if (!values.message) {
      errors.message = "Message is required";
    }

    return errors;
  };

  const onSubmit = (values, { setSubmitting }) => {
    setLoading(true);
    let data = new FormData();
    data.append("name", values.name);
    data.append("email", values.email);
    // data.append("subject", values.subject);
    data.append("phone", values.phone);
    data.append("message", values.message);

    Api.sendContactUs(data)
      .then((res) => {
        if (res?.status === 200) {
          setLoading(false);
          setSubmitting(false);
          handleResetForm();
          setShowDialog(true);
        }
      })
      .catch((res) => {
        setErrorMessage(res.response?.data?.message);
        setLoading(false);
        setShowErrorDialog(true);
        setSubmitting(false);
      });
  };

  const handleClose = () => {
    setShowDialog(false);
    return navigate("/");
  };

  return (
    <>
      <section>
        <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto mt-5 mb-5 lg:py-0">
          <div className="w-full bg-white rounded-lg shadow md:mt-0 sm:max-w-5xl xl:p-0 dark:bg-gray-800">
            <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
              <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
                Contact Us
              </h1>
              <p className="text-black-500 py-0 mt-2">
                Please fill out the form below to request more information about Healthcare Referral Pro and how you can become a member.
              </p>
              {loading ? (
                <Loader />
              ) : (
                <Formik
                  initialValues={{
                    name: "",
                    email: "",
                    phone: "",
                    message: "",
                  }}
                  validate={validate}
                  onSubmit={onSubmit}
                  innerRef={formikRef}
                >
                  {({ isSubmitting, errors }) => (
                    <Form>
                      <div className="mt-8 grid grid-cols-1 md:grid-cols-2 gap-6">
                        <div className="md:col-span-2">
                          <CustomInput label="Full Name" name="name" />
                        </div>
                        <div className="md:col-span-2">
                          <CustomInput
                            type="text"
                            label="Phone Number"
                            name="phone"
                            onKeyPress={(e) => {
                              const allowedKeys = /[0-9\b\r\t]/;
                              if (!allowedKeys.test(e.key)) {
                                e.preventDefault();
                              }
                            }}
                          />
                        </div>
                        <div className="md:col-span-2">
                          <CustomInput
                            label="Email Address"
                            name="email"
                            type="email"
                          />
                        </div>
                        {/* <div className="md:col-span-2"> <CustomInput label="Subject" name="subject" /> </div> */}
                        <div className="md:col-span-2">
                          <CustomInput
                            label="Message"
                            name="message"
                            type="textarea"
                          />
                        </div>
                      </div>

                      {Object.keys(errors).length > 0 && (
                        <div className="text-red-500 mt-2 mb-2 flex justify-center">
                          Please fill out all required fields.
                        </div>
                      )}
                      <div className="mt-2 flex justify-center">
                        <CustomButton type="submit" disabled={isSubmitting}>
                          {isSubmitting ? "Submitting..." : "Send"}
                        </CustomButton>
                        <ResetButton onClick={handleResetForm}>Reset</ResetButton>
                      </div>
                    </Form>
                  )}
                </Formik>
              )}
            </div>
          </div>
          <SweetAlert
            success
            show={showDialog}
            title="Thank you for contacting us! We will get back to you shortly."
            onConfirm={handleClose}
            confirmBtnCssClass="bg-primary-500 rounded-lg text-xl text-white hover:bg-blue-600"
          />
          <SweetAlert
            error
            show={showErrorDialog}
            title={errorMsg}
            onConfirm={handleCloseError}
            confirmBtnCssClass="bg-primary-500 rounded-lg text-xl text-white hover:bg-blue-600"
          />
        </div>
      </section>
    </>
  );
};

export default ContactUs;
