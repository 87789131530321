import { Form, Formik, Field, ErrorMessage } from "formik";
import { Api } from "../../api/api";
import { errorToast, successToast } from "../../helper/helper";
import { useNavigate, Link } from "react-router-dom";
import { useState } from "react";
import SweetAlert from 'react-bootstrap-sweetalert';

const ForgotPassword = () => {
  const [loading, setLoading] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    email: "",
  });

  const validate = (values) => {
    const errors = {};
    if (!values.email) {
      errors.email = "Email is Required";
    } else if (!/\S+@\S+\.\S+/.test(values.email)) {
      errors.email = "Invalid email address";
    }
    return errors;
  };

  const onSubmit = (values, { setSubmitting, resetForm }) => {
    setLoading(true);
    setFormData(values);
    setSubmitting(false);
    let data = new FormData();
    data.append("email", values.email);
    Api.forgotPassword(data)
      .then((res) => {
        if (res?.status === 200) {
          setShowDialog(true);
          successToast(res.data.message);
        }
        setFormData({});
        resetForm();
      })
      .catch((res) => {
        errorToast(res.response?.data?.message);
        setLoading(false);
      });
  };
  const handleClose = () => {
    setShowDialog(false);
    return navigate("/login");
  };
  return (
    <>
      <section>
        <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto mt-5 mb-5 lg:py-0">
          <div className="w-full bg-white rounded-lg shadow md:mt-0 sm:max-w-lg xl:p-0">
            <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
              <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
                Forgot Password
              </h1>
              <p className="text-black-500 py-0 mt-2">Please enter your email address to forgot password</p>
              <Formik
                initialValues={formData}
                validate={validate}
                onSubmit={onSubmit}
              >

                <Form className="space-y-4 md:space-y-6">
                  <div>
                    <div className="flex">
                      <span className="inline-flex items-center px-3 text-sm text-gray-900 border-1 rounded-l-lg dark:border-gray login-input-span">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="black" d="M4 20q-.825 0-1.412-.587T2 18V6q0-.825.588-1.412T4 4h16q.825 0 1.413.588T22 6v12q0 .825-.587 1.413T20 20zm8-7l8-5V6l-8 5l-8-5v2z" /></svg>
                      </span>
                      <Field
                        as={'input'}
                        type={"text"}
                        id={'email'}
                        name={'email'}
                        autoComplete="off"
                        placeholder={'Email'}
                        className="rounded-r-lg bg-gray-50 border border-gray-300 text-gray-900 focus:ring-blue-500 focus:border-blue-500 block flex-1 min-w-0 w-full text-sm p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 login-input"
                      />
                    </div>
                    <ErrorMessage name={'email'}>
                      {(msg) => <div className="text-red-500 text-sm mt-1">{msg}</div>}
                    </ErrorMessage>
                  </div>
                  <div className="flex items-center justify-end">
                    <Link to="/login" className="text-sm font-medium text-primary-500 hover:underline dark:text-primary-500">Login</Link>
                  </div>
                  {loading ? (
                    <div className="flex justify-center items-center">
                      <div className="animate-spin rounded-full h-10 w-10 border-b-2 border-gray-900"></div>
                    </div>
                  ) : (
                    <button type="submit" className="w-full text-white bg-primary-500 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800">Submit</button>
                  )}
                </Form>
              </Formik>

            </div>
          </div>
        </div>
        <SweetAlert
          success
          show={showDialog}
          title={'Password Reset Link Sent successfully. Please check your email.'}
          onConfirm={handleClose}
          confirmBtnCssClass="bg-primary-500 rounded-lg text-xl text-white hover:bg-blue-600"
        />
      </section>
    </>
  );
};

export default ForgotPassword;
