import React, { useState, useEffect } from "react";
import { Form, Formik, ErrorMessage, Field } from "formik";
import { Link } from "react-router-dom";
import { Api } from "../../api/api";
import {
  errorToast,
  setAccessToken,
  setJWTToken,
  setUserData,
  successToast,
} from "../../helper/helper";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { setTokens, setUser } from "@store/userSlice";
import SweetAlert from 'react-bootstrap-sweetalert';

const Login = () => {
  const { isLoggedIn } = useSelector((state) => state.user);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const [errorMsg, setErrorMessage] = useState('');
  const [showErrorDialog, setShowErrorDialog] = useState(false);

  const navigate = useNavigate();
  useEffect(() => {
    if (isLoggedIn) {
      return navigate("/portal/dashboard");
    }
  }, []);
  const validate = (values) => {
    const errors = {};

    if (!values.email) {
      errors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(values.email)) {
      errors.email = "Invalid email address";
    }

    if (!values.password) {
      errors.password = "Password is required";
    }

    return errors;
  };

  const handleCloseError = () => {
    setShowErrorDialog(false);
  };

  const onSubmit = (values, { setSubmitting }) => {
    setLoading(true);
    setFormData(values);
    let data = new FormData();
    data.append("email", values.email);
    data.append("password", values.password);

    Api.login(data)
      .then((res) => {
        setLoading(false);
        if (res?.status === 200) {
          const userData = res.data.data;
          setJWTToken(res.data.data.token);
          setUserData(JSON.stringify(res.data.data));
          setAccessToken(res.data.data.token);
          dispatch(setTokens(res.data.data));
          setSubmitting(true);
          dispatch(setUser(res.data.data));
          successToast(res.data.message, 4);
          setLoading(false);
          if (((userData.phone === null || userData.phone === '') || (userData.city === null || userData.city === '' || userData.city === '-') || (userData.state === null || userData.state === '' || userData.state === '-') || (userData.facility_name === null || userData.facility_name === '') || (userData.email === null || userData.email === '')) && userData?.role != 'delegate_access') {
            navigate("/portal/profile")
          }
          else {
            if (userData.current_subscription_plan_id == null && userData?.role != 'delegate_access') {
              navigate("/portal/subscription");
            } else {
              navigate("/portal/dashboard");
            }
          }
          // }, 2000);
        }
      })
      .catch((res) => {
        setErrorMessage(res.response?.data?.message);
        setLoading(false);
        setShowErrorDialog(true);
      });
  };
  return (
    <>
      <section>
        <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto mt-5 mb-5 lg:py-0">
          <div className="w-full bg-white rounded-lg shadow md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800">
            <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
              <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
                Sign in to your account
              </h1>
              <p className="text-black-500 py-0 mt-2">
                Welcome back! please enter your details
              </p>

              <Formik
                initialValues={formData}
                validate={validate}
                onSubmit={onSubmit}
              >
                {({ isSubmitting }) => (
                  <Form className="space-y-4 md:space-y-6">
                    <div>
                      <div className="flex">
                        <span className="inline-flex items-center px-3 text-sm text-gray-900 border-1 rounded-l-lg dark:border-gray login-input-span">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                          >
                            <path
                              fill="black"
                              d="M4 20q-.825 0-1.412-.587T2 18V6q0-.825.588-1.412T4 4h16q.825 0 1.413.588T22 6v12q0 .825-.587 1.413T20 20zm8-7l8-5V6l-8 5l-8-5v2z"
                            />
                          </svg>
                        </span>
                        <Field
                          as={"input"}
                          type={"text"}
                          id={"email"}
                          name={"email"}
                          autoComplete="off"
                          placeholder={"Email"}
                          className="rounded-r-lg bg-gray-50 border border-gray-300 text-gray-900 focus:ring-blue-500 focus:border-blue-500 block flex-1 min-w-0 w-full text-sm p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 login-input"
                        />
                      </div>
                      <ErrorMessage name={"email"}>
                        {(msg) => (
                          <div className="text-red-500 text-sm mt-1">{msg}</div>
                        )}
                      </ErrorMessage>
                    </div>
                    <div>
                      <div className="flex">
                        <span className="inline-flex items-center px-3 text-sm text-gray-900 border rounded-l-lg dark:text-gray-400 dark:border-gray-600 login-input-span">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                          >
                            <path
                              fill="black"
                              d="M6 22q-.825 0-1.412-.587T4 20V10q0-.825.588-1.412T6 8h1V6q0-2.075 1.463-3.537T12 1t3.538 1.463T17 6v2h1q.825 0 1.413.588T20 10v10q0 .825-.587 1.413T18 22zm6-5q.825 0 1.413-.587T14 15t-.587-1.412T12 13t-1.412.588T10 15t.588 1.413T12 17M9 8h6V6q0-1.25-.875-2.125T12 3t-2.125.875T9 6z"
                            />
                          </svg>
                        </span>
                        <Field
                          as={"input"}
                          type={"password"}
                          id={"password"}
                          name={"password"}
                          autoComplete="off"
                          placeholder={"Password"}
                          className="rounded-r-lg bg-gray-50 border border-gray-300 text-gray-900 focus:ring-blue-500 focus:border-blue-500 block flex-1 min-w-0 w-full text-sm p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 login-input"
                        />
                      </div>
                      <ErrorMessage name={"password"}>
                        {(msg) => (
                          <div className="text-red-500 text-sm mt-1">{msg}</div>
                        )}
                      </ErrorMessage>{" "}
                    </div>
                    <div className="flex items-center justify-between">
                      <div className="flex items-start">
                        <div className="flex items-center h-5">
                          <input
                            id="remember"
                            aria-describedby="remember"
                            type="checkbox"
                            className="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-primary-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-primary-600 dark:ring-offset-gray-800"
                          />
                        </div>
                        <div className="ml-3 text-sm">
                          <label
                            htmlFor="remember"
                            className="text-gray-500 dark:text-gray-300"
                          >
                            Remember me
                          </label>
                        </div>
                      </div>
                      <Link
                        to="/forgot-password"
                        className="text-sm font-medium text-primary-500 hover:underline dark:text-primary-500"
                      >
                        Forgot password?
                      </Link>
                    </div>
                    {loading ? (
                      <div className="flex justify-center items-center">
                        <div className="animate-spin rounded-full h-10 w-10 border-b-2 border-gray-900"></div>
                      </div>
                    ) : (
                      <button
                        type="submit"
                        className="w-full text-white bg-primary-500 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
                      >
                        Sign in
                      </button>
                    )}
                    <p className="text-sm font-light text-gray-500 dark:text-gray-400">
                      Don’t have an account yet?{" "}
                      <Link
                        to="/register"
                        className="font-medium text-primary-500 hover:underline dark:text-primary-500"
                      >
                        Sign up
                      </Link>
                    </p>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
        <SweetAlert
          error
          show={showErrorDialog}
          title={errorMsg}
          onConfirm={handleCloseError}
          confirmBtnCssClass="bg-primary-500 rounded-lg text-xl text-white hover:bg-blue-600"
        />
      </section>
    </>
  );
};
export default Login;
