import React, { useEffect, useCallback, useState } from "react";
import { List, MainItem } from "../style";
import { colors } from "@styles/vars";
import Accordion from "react-bootstrap/Accordion";
import { NavLink } from "react-router-dom";
import { useSidebarContext } from "@contexts/sidebarContext";
import { useDispatch, useSelector } from "react-redux";
import Btn from "@ui/Btn";
import { useNavigate } from "react-router";
import moment from "moment/moment";
import { Api } from "api/api";
import { setUser } from "@store/userSlice";
import { DynamicDelegateMenu } from "helper/helper";
import { Stack, Badge } from "@mui/material";
import { connectSocket } from "@pages/Chats";

const Content = () => {
  const { toggleSidebar } = useSidebarContext();
  const activeStyle = { color: colors.blue };
  const { user } = useSelector((state) => state.user);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [socket, setSocket] = useState(connectSocket);
  const [count, setCount] = useState();

  const upgradeHandler = () => {
    navigate("/portal/upgrade-plan");
  };

  if (socket?.id) {
    socket.emit("unread_user_count", {
      login_id: user.id,
    });
    socket.on(`unread_user_count_${user.id}`, (data) => {
      setCount(data);
    });
  }

  useEffect(() => {
    if (socket?.id) {
      // const intervalId = setInterval(() => {
      socket.emit("unread_user_count", {
        login_id: user.id,
      });
      // }, 1000);

      // return () => {
      //   clearInterval(intervalId);
      // };
    }
  }, [socket, user]);
  const current_subscription_plan_expiry_date =
    user?.current_subscription_plan_expiry_date;
  const planExpiryDate = moment(current_subscription_plan_expiry_date).format(
    "YYYY-MM-DD"
  );
  const newDate = moment(current_subscription_plan_expiry_date)
    .subtract(10, "days")
    .format("YYYY-MM-DD");
  const todayDate = moment().format("YYYY-MM-DD");

  // const handleBuyNow = useCallback(() => {
  //   let data = new FormData();
  //   data.append("membership_id", 1);
  //   Api.updateMembership(data).then((res) => {
  //     if (res?.status === 200) {
  //       Api.getProfile().then((userRes) => {
  //         dispatch(setUser(userRes.data.data));
  //       });
  //     }
  //   });
  // }, [dispatch]);

  // useEffect(() => {
  //   if (todayDate === planExpiryDate) {
  //     handleBuyNow();
  //   }
  // }, [todayDate, planExpiryDate, handleBuyNow]);

  const renderMenuItems = (menuItems) => {
    return menuItems?.map((item, index) =>
      item?.name !== "Upgrade Plans" ? (
        <MainItem
          as={NavLink}
          to={item.path}
          onClick={() => toggleSidebar()}
          style={({ isActive }) => (isActive ? activeStyle : undefined)}
          key={index}
        >
          <i className={`icon icon-${item.icon}`}></i> {item?.name}
          {item?.name === "Chat" && (
            <Stack
              spacing={2}
              alignItems="center"
              style={{ marginRight: "10px" }}
            >
              <Badge color="primary" badgeContent={count}></Badge>
            </Stack>
          )}
        </MainItem>
      ) : (
        <>
          {/* {(user?.current_subscription_plan_name === null ||
            todayDate > newDate) && ( */}
          {/* <Btn text="Upgrade Membership" handler={upgradeHandler}></Btn> */}
          {/* )} */}
        </>
      )
    );
  };

  const renderDynamicMenu = () => {
    return renderMenuItems(DynamicDelegateMenu("menu"));
  };

  const logoutLink = { name: "Logout", path: "/logout", icon: "logout" };

  return (
    <List as={Accordion}>
      {renderDynamicMenu()}
      {renderMenuItems([logoutLink])}

      {/* <MainItem
        as={NavLink}
        to={'/portal/chat'}
        onClick={() => toggleSidebar()}
        style={({ isActive }) => (isActive ? activeStyle : undefined)}
      >
        <i className={`icon icon-circle-user`}></i> Chat
      </MainItem> */}
      {/* {(user?.current_subscription_plan_name === null ||
        todayDate > newDate) && ( */}
      <Btn
        text="Upgrade Membership"
        handler={() => {
          upgradeHandler();
          toggleSidebar();
        }}
      ></Btn>
      {/* )} */}
      <MainItem
        as={NavLink}
        to={"/portal/refer-a-facility"}
        onClick={() => toggleSidebar()}
        style={({ isActive }) => (isActive ? activeStyle : undefined)}
        className={"pin-down"}
      >
        <i className={`icon icon-circle-user`}></i> Refer A Facility
      </MainItem>
    </List>
  );
};

export default Content;
