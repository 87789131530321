import { dashboardRoute } from "@constants/dashboardRoute";
import DelegateRoute from "frontend/routing/delegateRoute";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
export const getLocalStorageItem = (key) => localStorage.getItem(key);
export const setLocalStorageItem = (key, value) =>
  localStorage.setItem(key, value);
export const removeLocalStorageItem = (key) => localStorage.removeItem(key);
export const cleanLocalStorage = () => localStorage.clear();
export const getJWTToken = () => "Bearer " + localStorage.getItem("token");
export const setJWTToken = (token) => localStorage.setItem("token", token);
export const setUserData = (data) => localStorage.setItem("userData", data);
export const getUserData = () => localStorage.getItem("userData");
export const getToken = () => localStorage.getItem("token");
export const setAccessToken = (token) =>
  localStorage.setItem("accessToken", token);
export const getAccessToken = () => localStorage.getItem("accessToken");

export const getUserName = () => {
  const userInfo = getUserData();
  if (userInfo) {
    const userData = JSON.parse(userInfo);
    return userData.profile;
  }
  return null;
};

export const checkIsLoggedIn = () => {
  const res = getToken();
  if (res) {
    return true;
  }
};

export const errorToast = (msg, toastId = "") =>
  toast.error(msg, {
    duration: 3000,
    id: toastId,
    position: "top-right",
  });

export const successToast = (msg, duration = 3000) =>
  toast.success(msg, {
    duration,
    position: "top-right",
  });

export const informativeToast = (msg, duration = 3000) =>
  toast.custom(
    (t) => (
      <div
        className={`${t.visible ? "animate-enter" : "animate-leave"
          } max-w-md w-full bg-white shadow-lg rounded-lg pointer-events-auto flex ring-1 ring-black ring-opacity-5`}
      >
        <div className="flex-1 w-0 p-2">
          <div className="flex items-start">
            <div className="self-center"></div>
            <div className="ml-3 self-center">
              <p className="mt-1 text-gray-500">{msg}</p>
            </div>
          </div>
        </div>
      </div>
    ),
    {
      duration,
    }
  );
export const useOutsideClick = (ref, callback) => {
  const handleClick = (e) => {
    if (ref.current && !ref.current.contains(e.target)) {
      callback();
    }
  };
  useEffect(() => {
    document.addEventListener("click", handleClick);
    return () => {
      document.removeEventListener("click", handleClick);
    };
  });
};
function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}
export default function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );
  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return windowDimensions;
}
export const setReferenceData = (data) => {
  localStorage.setItem("referenceData", JSON?.stringify(data));
};
export const getReferenceData = () =>
  JSON?.parse(localStorage.getItem("referenceData"));
export const removeReferenceData = () =>
  localStorage.removeItem("referenceData");
export const storeReferralInfo = (data) => {
  localStorage.setItem("referralData", JSON.stringify(data));
};
export const getReferralData = () =>
  JSON.parse(localStorage.getItem("referralData"));
export const removeReferralData = () => localStorage.removeItem("referralData");

export const setFacilityTypeList = (data) =>
  localStorage.setItem("facility_type", JSON.stringify(data));
export const getFacilityTypeList = (data) =>
  JSON.parse(localStorage.getItem("facility_type"));

export const setStateListData = (data) =>
  localStorage.setItem("state_list", JSON.stringify(data));
export const getStateListData = (data) =>
  JSON.parse(localStorage.getItem("state_list"));

export const setInsuranceTypeData = (data) =>
  localStorage.setItem("insurance_type_list", JSON.stringify(data));

export const getInsuranceTypeData = (data) =>
  JSON.parse(localStorage.getItem("insurance_type_list"));

export const resetInsuranceType = () =>
  localStorage.removeItem("insurance_type_list");

export const DynamicDelegateMenu = (type) => {
  const { user } = useSelector((state) => state.user);
  if (type === "menu") {
    const menuItems = [];
    dashboardRoute.filter((item) => {
      if (user.frontend_modules_permissions[item.key]) {
        menuItems.push(user.frontend_modules_permissions[item.key]);
      }
    });

    return dashboardRoute.filter((item) => {
      return menuItems.some(
        (dataItem) =>
          dataItem.isSidebar === true &&
          dataItem.name == item.name &&
          dataItem.isAccess === true
      );
    });
  } else {
    const menuItems = [];
    dashboardRoute?.filter((item) => {
      if (user.frontend_modules_permissions[item.key]) {
        menuItems.push(user.frontend_modules_permissions[item.key]);
      }
    });

    return dashboardRoute.filter((item) => {
      return menuItems.some(
        (dataItem) => dataItem.name === item.name && dataItem.isAccess === true
      );
    });
  }
};
