import { useState, useEffect } from "react";
import { Image } from "react-bootstrap";
import Logo from "../../assets/images/healthcare-referral-logo-small.png";
import { accountLink, headerNav } from "../../helper/navLink";
import { Link } from "react-router-dom";
import { FaPhoneAlt } from "react-icons/fa";
import { useSelector } from "react-redux";
import "../../assets/css/style.css";
import "../../assets/css/media.css";
import AOS from "aos";
import "aos/dist/aos.css";
import Helmet from "react-helmet";
import { Api } from "api/api";
import { useLocation } from "react-router";
const Header = () => {
  useEffect(() => {
    AOS.init({
      duration: 800,
      once: false,
    });
  }, []);

  const { user, isLoggedIn } = useSelector((state) => state.user);
  const [scrolled, setScrolled] = useState(false);
  const [show, setShow] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const url = window.location.origin;
  const [seo, setSEOData] = useState();
  const location = useLocation().pathname;
  console.log('url', window.location);
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
  });

  useEffect(() => {
    const formData = new FormData();
    formData.append("page_name", location == '/' ? 'home' : location.replace('/', ''));
    Api.seo(formData).
      then((res) => {
        setSEOData(res.data.data)
      })
      .catch((res) => {
      });
  }, [url, location])

  const handleScroll = () => {
    const offset = window.scrollY;
    if (offset > 10) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
    setScreenWidth(window.innerWidth);
  };
  return (
    <>
      <Helmet>
        <script
          rel="preload"
          src="https://cdn.tailwindcss.com"
          as="script"
        ></script>
        <title>{seo?.meta_title}</title>
        <meta name="description" content={`${seo?.meta_description}`} />
        <meta name="title" content={`${seo?.meta_title}`} />
      </Helmet>

      <header
        className={`sticky ${scrolled && screenWidth > 768 ? "fixed" : ""
          } main-header"`}
      >
        <div className="flex md:flex-row flex-col justify-center items-center md:justify-between container-fluid ">
          <div className="w-4/6 md:w-52">
            <Link to="/">
              <Image src={Logo} alt="" />
            </Link>
          </div>
          <div className="rpart flex flex-col justify-center items-center md:flex-row-reverse">
            <ul className="bttn-box pl-0">
              <li className="flex justify-center">
                <Link to={isLoggedIn ? "/logout" : "/login"}>
                  <button className="member call-now  md:hidden inline-flex justify-center items-center gap-2">
                    <>{isLoggedIn ? "Logout" : "Login"}</>
                  </button>
                </Link>
              </li>
              <li className="flex justify-center md:pl-2">
                {isLoggedIn ? (
                  <div className="relative">
                    <button
                      className=" flex items-center text-xl justify-center border px-4 py-3 border-secondaryColor focus:outline-none hover:bg-secondaryColor"
                      onClick={() => setIsOpen(!isOpen)}
                    >
                      My Account
                    </button>
                    {isOpen === true ? (
                      <div
                        className="absolute right-0 mt-2 w-48 bg-white border border-gray-300 rounded-md shadow-lg"
                        style={{ zIndex: 999999 }}
                      >
                        {/* <button className="block w-full px-4 py-2 text-left text-gray-800 hover:bg-gray-100">
                          {user?.profile}
                        </button>
                        <div className="border-b border-gray-300"></div> */}
                        {user?.role !== "delegate_access"
                          ? accountLink?.map((item, i) => {
                            return (
                              <>
                                <Link
                                  key={i}
                                  to={item.path}
                                  className="block w-full px-4 py-2 text-left text-gray-800 hover:bg-gray-100"
                                >
                                  {item.title}
                                </Link>
                                <div className="border-t border-gray-300"></div>
                              </>
                            );
                          })
                          : accountLink?.map((item, i) => {
                            return (
                              item?.delegate && (
                                <>
                                  <Link
                                    key={i}
                                    to={item.path}
                                    className="block w-full px-4 py-2 text-left text-gray-800 hover:bg-gray-100"
                                  >
                                    {item.title}
                                  </Link>
                                  <div className="border-t border-gray-300"></div>
                                </>
                              )
                            );
                          })}
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                ) : (
                  <>
                    <Link style={{ display: show ? "none" : "" }} to={"/login"}>
                      <button className="member hidden sm:block md:bg-transparent me-4 text-uppercase">
                        Login
                      </button>
                    </Link>
                    <Link to={"/register"}>
                      <button className="member md:bg-transparent text-uppercase">
                        Register
                      </button>
                    </Link>
                  </>
                )}
              </li>
            </ul>
            <nav className="custnav sticky-top">
              <div id="mobilemenu" onClick={() => setShow(!show)}></div>
              <ul className="menus " style={{ display: show ? "block" : "" }}>
                {headerNav?.map((item, i) => {
                  return (
                    <li key={i}>
                      {/* <Link to={item.path} onClick={() => setShow(!show)}>{item.title}</Link> */}
                      <Link
                        to={item.path}
                        onClick={() => {
                          if (screenWidth < 768) {
                            setShow(!show);
                          }
                        }}
                      >
                        {item.title}
                      </Link>
                    </li>
                  );
                })}

                {/* {isLoggedIn ? (
                  // <li>
                  //   <Link to={"/logout"}>Logout</Link>
                  // </li>
                  ""
                ) : (
                  // <li style={{ display: show ? "none" : "" }}>
                  //   <Link to={"/login"}>Login</Link>
                  // </li>
                )} */}
              </ul>
            </nav>
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;
