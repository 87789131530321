import { Link } from "react-router-dom";
import "./style.css";

export const StaticPageLayout = ({ props }) => {
  return (
    <>
      <div className="static-page-wrapper">
        <div className="page-header">
          <div className="static-container">
            <h1>{props?.title}</h1>
            {props?.subtitle ? <h6>{props?.subtitle}</h6> : ""}
          </div>
        </div>
        <div className="static-container description">
          {props.descTitle && <h3>{props.descTitle}</h3>}
          <div>{props?.description}</div>
          {props?.listData ? (
            <div className="list-data">
              <ul>
                {props?.listData.map((list) => (
                  <li>{list}</li>
                ))}
              </ul>
            </div>
          ) : (
            ""
          )}
        </div>

        <div className="static-container">
          <Link to={"/register"} className="primary-button">
            Become a member
          </Link>
          <Link to={"/contact"} className="primary-button">
            contact us
          </Link>
        </div>
      </div>
    </>
  );
};
